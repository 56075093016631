@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center {
	height: 93px;
}
.form {
	display: none;
}
.mint {
	margin-top: -10px;
}
.numberstyle-qty {
	padding-bottom: 34px;
}
/*
 * Pleasing the eye
 */

 $inputBg: #FFFFFF;
 $inputText: #747474;
 $bg-1: #E3E0DD;
 $bg-2: #EEE5DA;
 
 html,
 body {
   display: flex;
   width: 100%;
   height: 100%;
   justify-content: space-around;
   align-items: center;
   padding: 0;
   margin: 0;
   color: $inputText;
   background: linear-gradient(to bottom right, $bg-1, $bg-2);
   font-family: 'Source Sans Pro', sans-serif;
	 overflow: hidden;
 }
 
 
 
 /*
  * Plugin style
  */
 .numberstyle-qty {
	width: 139px;
   position: relative;
	 border-radius: 6px;
   background-color: $inputBg;
   overflow: hidden;
	 box-shadow: 0 3px 6px rgba($inputText,0.25);
 }
 
 .numberstyle-qty input,
 .numberstyle-qty .qty-btn {
   position: relative;
   display: block;
   float: left;
   height: 40px;
   padding: 0;
   margin: 0;
	 border: 0px;
   text-align: center;
   line-height: 40px;
   outline: 0;
   box-shadow: 0px;
 }
   
 .numberstyle-qty .qty-btn {
   width: 40px;
   font-size: 18px;
   cursor: pointer;
   z-index: 2;
   -webkit-user-select: none;
       -ms-user-select: none;
           user-select: none;
   transition: all 250ms ease;
 }  
 
 .numberstyle-qty .qty-btn.disabled {
   cursor: default;
   color: rgba($inputText,0.5);
 }
   
 .numberstyle-qty input {
   width: 50px;
   border-left: 0;
   border-right: 0;
	 color: $inputText;
   font-size: 18px;
	 font-weight: 700;
   z-index: 1;
 }
 
 .numberstyle-qty input:focus {
   outline: 0;
   box-shadow: 0px;
 }
	 
 .numberstyle-qty input[type=number]::-webkit-inner-spin-button,
 .numberstyle-qty input[type=number]::-webkit-outer-spin-button {
   -webkit-appearance: none;
   margin: 0;
 }
	 
 .numberstyle-qty input[type=number] {
   -moz-appearance: textfield;
 }
 
 
 /*
  * More from me link 🔥
  */
 .more {
	 position: absolute;
	 bottom: 15px;
	 right: 15px;
	 padding: 7px 21px;
	 border-radius: 8px;
	 color: #fff;
	 background-color: rgba(0,0,0,.2);
	 font-family: 'Source Sans Pro', sans-serif;
	 font-size: 0.7rem;
	 font-weight: 700;
	 text-transform: uppercase;
	 text-decoration: none;
	 overflow: hidden;
	 z-index: 1;
	 -webkit-animation: reveal 5000ms cubic-bezier(.87,-.41,.19,1.44);
	         animation: reveal 5000ms cubic-bezier(.87,-.41,.19,1.44);
	 
	 &::before {
		 display: block;
		 content: "";
		 position: absolute;
		 top: 50%;
		 left: 50%;
		 width: 100%;
		 max-width: 20%;
		 height: 100%;
		 border-radius: 8px;
		 background-color: rgba(0,0,0,0);
		 transition: max-width 0ms ease 250ms,
						 background-color 250ms ease;
		 -webkit-transform: translate(-50%, -50%);
		         transform: translate(-50%, -50%);
		 z-index: -1;
	 }
	 
	 &:hover {
		 &::before {
			 max-width: 100%;
			 background-color: rgba(0,0,0,0.6);
			 transition: all 250ms ease;
		 }
	 }
 }
 
 @-webkit-keyframes reveal {
	 0%, 90% {
		  bottom: -30px;
	 }
	100% {
		  bottom: 15px;
	 }
 }
 
 @keyframes reveal {
	 0%, 90% {
		  bottom: -30px;
	 }
	100% {
		  bottom: 15px;
	 }
 }


 @font-face { 
	font-family:'Avenir';
	font-style:normal;
	font-weight:normal;
	font-display:auto;
	src:url('https://mint.chummyfinclub.com/wp-content/uploads/2022/03/AvenirLTStd-Book.woff') format('woff');
  } 
  @font-face { 
	font-family:'Avenir';
	font-style:normal;
	font-weight:bold;
	font-display:auto;
	src:url('https://mint.chummyfinclub.com/wp-content/uploads/2022/03/AvenirLTStd-Black.woff') format('woff');
  } 
  @font-face { 
	font-family:"Inter var";
	font-weight:100 900;
	/* stylelint-disable-line font-weight-notation */
	  font-style:italic;
	font-display:swap;
	src:url(https://mint.chummyfinclub.com/wp-content/themes/twentytwenty/assets/fonts/inter/Inter-italic-var.woff2) format("woff2");
  } 
  @media all{ 
	* { 
	  word-break: break-word; 
	  word-wrap: break-word; 
	  overflow-wrap: break-word;
	} 
  }     
  
  @media all{ 
	*  { 
	  box-sizing: border-box;
	} 
  }     
  
  @media all{ 
	.elementor-column { 
	  min-height: 1px;
	} 
  }     
  
  @media all{ 
	.elementor-column { 
	  position: relative; 
	  display: flex;
	} 
  }     
  
  @media (min-width: 768px){ 
	.elementor-column.elementor-col-100 { 
	  width: 100%;
	} 
  }     
  
  @media all{ 
	.elementor-section .elementor-container { 
	  display: flex; 
	  margin-right: auto; 
	  margin-left: auto; 
	  position: relative;
	} 
  }     
  
  @media all{ 
	.elementor-section.elementor-section-boxed > .elementor-container { 
	  max-width: 1140px;
	} 
  }     
  
  @media all{ 
	.elementor-9 .elementor-element.elementor-element-82826a6 > .elementor-container { 
	  max-width: 900px;
	} 
  }     
  
  @media all{ 
	section { 
	  padding: 5rem 0; 
	  width: 100%; 
	  padding-top: 5rem; 
	  padding-right: 0px; 
	  padding-bottom: 5rem; 
	  padding-left: 0px;
	} 
  }     
  
  @media (min-width: 700px){ 
	section { 
	  padding: 8rem 0; 
	  padding-top: 8rem; 
	  padding-right: 0px; 
	  padding-bottom: 8rem; 
	  padding-left: 0px;
	} 
  }     
  
  @media all{ 
	.elementor-section { 
	  position: relative;
	} 
  }     
  
  @media all{ 
	.elementor-9 .elementor-element.elementor-element-82826a6 { 
	  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s; 
	  padding: 6.125rem 0rem 8.75rem 0rem; 
	  transition-duration: 0.3s, 0.3s, 0.3s, 0.3s; 
	  transition-timing-function: ease, ease, ease, ease; 
	  transition-delay: 0s, 0s, 0s, 0s; 
	  transition-property: background, border, border-radius, box-shadow; 
	  padding-top: 6.125rem; 
	  padding-right: 0rem; 
	  padding-bottom: 8.75rem; 
	  padding-left: 0rem;
	} 
  }     
  
  @media all{ 
	.elementor-9 .elementor-element.elementor-element-82826a6:not(.elementor-motion-effects-element-type-background) { 
	  background-color: rgb(255, 255, 255);
	} 
  }     
  
  @media all{ 
	.elementor { 
	  -webkit-hyphens: manual; 
	      -ms-hyphens: manual; 
	          hyphens: manual;
	} 
  }     
  
  @media all{ 
	body { 
	  border: none; 
	  margin: 0; 
	  padding: 0; 
	  border-top-width: medium; 
	  border-top-width: initial; 
	  border-right-width: medium; 
	  border-right-width: initial; 
	  border-bottom-width: medium; 
	  border-bottom-width: initial; 
	  border-left-width: medium; 
	  border-left-width: initial; 
	  border-top-style: none; 
	  border-right-style: none; 
	  border-bottom-style: none; 
	  border-left-style: none; 
	  border-top-color: currentColor; 
	  border-top-color: initial; 
	  border-right-color: currentColor; 
	  border-right-color: initial; 
	  border-bottom-color: currentColor; 
	  border-bottom-color: initial; 
	  border-left-color: currentColor; 
	  border-left-color: initial; 
	  border-image-source: initial; 
	  border-image-slice: initial; 
	  border-image-width: initial; 
	  border-image-outset: initial; 
	  border-image-repeat: initial; 
	  margin-top: 0px; 
	  margin-right: 0px; 
	  margin-bottom: 0px; 
	  margin-left: 0px; 
	  padding-top: 0px; 
	  padding-right: 0px; 
	  padding-bottom: 0px; 
	  padding-left: 0px;
	} 
  }     
  
  @media all{ 
	body { 
	  background: #f5efe0; 
	  box-sizing: border-box; 
	  color: rgb(0, 0, 0); 
	  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif; 
	  font-size: 1.8rem; 
	  letter-spacing: -0.015em; 
	  text-align: left; 
	  background-image: none; 
	  background-image: initial; 
	  background-position-x: 0; 
	  background-position-x: initial; 
	  background-position-y: 0; 
	  background-position-y: initial; 
	  background-size: auto auto; 
	  background-size: initial; 
	  background-repeat-x: initial; 
	  background-repeat-y: initial; 
	  background-attachment: scroll; 
	  background-attachment: initial; 
	  background-origin: padding-box; 
	  background-origin: initial; 
	  background-clip: border-box; 
	  background-clip: initial; 
	  background-color: rgb(245, 239, 224);
	} 
  }     
  
  @media all{ 
	body { 
	  font-family: "Inter var", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
	} 
  }     
  
  body { 
	  color: rgb(0, 0, 0);
  } 
  
  @media all{ 
	.elementor-kit-5 { 
	  --e-global-color-primary: #6EC1E4; 
	  --e-global-color-text: #7A7A7A; 
	  --e-global-color-accent: #61CE70; 
	  --e-global-typography-primary-font-family: "Roboto"; 
	  --e-global-typography-primary-font-weight: 600; 
	  --e-global-typography-text-font-family: "Roboto"; 
	  --e-global-typography-text-font-weight: 400; 
	} 
  }     
  
  @media all{ 
	html { 
	  border: none; 
	  margin: 0; 
	  padding: 0; 
	  border-top-width: medium; 
	  border-top-width: initial; 
	  border-right-width: medium; 
	  border-right-width: initial; 
	  border-bottom-width: medium; 
	  border-bottom-width: initial; 
	  border-left-width: medium; 
	  border-left-width: initial; 
	  border-top-style: none; 
	  border-right-style: none; 
	  border-bottom-style: none; 
	  border-left-style: none; 
	  border-top-color: currentColor; 
	  border-top-color: initial; 
	  border-right-color: currentColor; 
	  border-right-color: initial; 
	  border-bottom-color: currentColor; 
	  border-bottom-color: initial; 
	  border-left-color: currentColor; 
	  border-left-color: initial; 
	  border-image-source: initial; 
	  border-image-slice: initial; 
	  border-image-width: initial; 
	  border-image-outset: initial; 
	  border-image-repeat: initial; 
	  margin-top: 0px; 
	  margin-right: 0px; 
	  margin-bottom: 0px; 
	  margin-left: 0px; 
	  padding-top: 0px; 
	  padding-right: 0px; 
	  padding-bottom: 0px; 
	  padding-left: 0px;
	} 
  }     
  
  @media all{ 
	html { 
	  font-size: 62.5%; 
	  scroll-behavior: smooth;
	} 
  }     
  
  @media all{ 
	.elementor-widget-wrap { 
	  position: relative; 
	  width: 100%; 
	  flex-wrap: wrap; 
	  align-content: flex-start;
	} 
  }     
  
  @media all{ 
	.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap { 
	  display: flex;
	} 
  }     
  
  @media all{ 
	.elementor-element-populated  { 
	  padding: 10px; 
	  padding-top: 10px; 
	  padding-right: 10px; 
	  padding-bottom: 10px; 
	  padding-left: 10px;
	} 
  }     
  
  @media all{ 
	*,:before,:after { 
	  word-break: break-word; 
	  word-wrap: break-word; 
	  overflow-wrap: break-word;
	} 
  }     
  
  @media all{ 
	* ,:after ,:before  { 
	  box-sizing: border-box;
	} 
  }     
  
  @media all{ 
	.elementor-widget { 
	  position: relative;
	} 
  }     
  
  @media all{ 
	.elementor-element  { 
	  width: 100%;
	} 
  }     
  
  @media all{ 
	.elementor-widget:not(:last-child) { 
	  margin-bottom: 20px;
	} 
  }     
  
  @media all{ 
	.elementor-element.elementor-element-7e5f4ce  { 
	  text-align: center;
	} 
  }     
  
  @media all{ 
	.elementor-element .elementor-widget-container  { 
	  transition: background .3s,border .3s,border-radius .3s,box-shadow .3s; 
	  transition-duration: 0.3s, 0.3s, 0.3s, 0.3s, 0.3s, 0.3s; 
	  transition-timing-function: ease, ease, ease, ease, ease, ease; 
	  transition-delay: 0s, 0s, 0s, 0s, 0s, 0s; 
	  transition-property: background, border, border-radius, box-shadow;
	} 
  }     
  
  @media all{ 
	h2 { 
	  overflow-wrap: break-word;
	} 
  }     
  
  @media all{ 
	h2 { 
	  border: none; 
	  margin: 0; 
	  padding: 0; 
	  border-top-width: medium; 
	  border-top-width: initial; 
	  border-right-width: medium; 
	  border-right-width: initial; 
	  border-bottom-width: medium; 
	  border-bottom-width: initial; 
	  border-left-width: medium; 
	  border-left-width: initial; 
	  border-top-style: none; 
	  border-right-style: none; 
	  border-bottom-style: none; 
	  border-left-style: none; 
	  border-top-color: currentColor; 
	  border-top-color: initial; 
	  border-right-color: currentColor; 
	  border-right-color: initial; 
	  border-bottom-color: currentColor; 
	  border-bottom-color: initial; 
	  border-left-color: currentColor; 
	  border-left-color: initial; 
	  border-image-source: initial; 
	  border-image-slice: initial; 
	  border-image-width: initial; 
	  border-image-outset: initial; 
	  border-image-repeat: initial; 
	  margin-top: 0px; 
	  margin-right: 0px; 
	  margin-bottom: 0px; 
	  margin-left: 0px; 
	  padding-top: 0px; 
	  padding-right: 0px; 
	  padding-bottom: 0px; 
	  padding-left: 0px;
	} 
  }     
  
  @media all{ 
	h2 { 
	  -webkit-font-feature-settings: "lnum"; 
	          font-feature-settings: "lnum"; 
	  font-variant-numeric: lining-nums; 
	  font-weight: 700; 
	  letter-spacing: -0.0415625em; 
	  line-height: 1.25; 
	  margin: 3.5rem 0 2rem; 
	  margin-top: 3.5rem; 
	  margin-right: 0px; 
	  margin-bottom: 2rem; 
	  margin-left: 0px;
	} 
  }     
  
  @media all{ 
	h2 { 
	  font-size: 3.2rem;
	} 
  }     
  
  @media (min-width: 700px){ 
	h2 { 
	  margin: 6rem auto 3rem; 
	  margin-top: 6rem; 
	  margin-right: auto; 
	  margin-bottom: 3rem; 
	  margin-left: auto;
	} 
  }     
  
  @media (min-width: 700px){ 
	h2 { 
	  font-size: 4.8rem;
	} 
  }     
  
  .elementor-heading-title { 
	  padding: 0; 
	  margin: 0; 
	  line-height: 1; 
	  padding-top: 0px; 
	  padding-right: 0px; 
	  padding-bottom: 0px; 
	  padding-left: 0px; 
	  margin-top: 0px; 
	  margin-right: 0px; 
	  margin-bottom: 0px; 
	  margin-left: 0px;
  } 
  
  @media all{ 
	.elementor-widget-heading .elementor-heading-title  { 
	  color: var( --e-global-color-primary ); 
	  font-family: var( --e-global-typography-primary-font-family ), Sans-serif; 
	  font-weight: var( --e-global-typography-primary-font-weight );
	} 
  }     
  
  @media all{ 
	.elementor-element.elementor-element-7e5f4ce .elementor-heading-title  { 
	  color: rgb(0, 0, 0); 
	  font-family: Avenir, sans-serif; 
	  font-size: 2.25em; 
	  font-weight: bold;
	} 
  }     
  
  .elementor-accordion { 
	  text-align: left;
  } 
  
  @media all{ 
	.elementor-accordion-item { 
	  padding-bottom: 2em; 
	  border-radius: 0.5rem; 
	  padding-top: 2em; 
	  border: 2px solid black !important; 
	  padding-left: 3.125rem; 
	  padding-right: 3.125rem; 
	  margin-bottom: 15px; 
	  border-top-left-radius: 0.5rem; 
	  border-top-right-radius: 0.5rem; 
	  border-bottom-right-radius: 0.5rem; 
	  border-bottom-left-radius: 0.5rem; 
	  border-top-width: 2px !important; 
	  border-right-width: 2px !important; 
	  border-bottom-width: 2px !important; 
	  border-left-width: 2px !important; 
	  border-top-style: solid !important; 
	  border-right-style: solid !important; 
	  border-bottom-style: solid !important; 
	  border-left-style: solid !important; 
	  border-top-color: black !important; 
	  border-right-color: black !important; 
	  border-bottom-color: black !important; 
	  border-left-color: black !important; 
	  border-image-source: initial !important; 
	  border-image-slice: initial !important; 
	  border-image-width: initial !important; 
	  border-image-outset: initial !important; 
	  border-image-repeat: initial !important;
	} 
  }     
  
  .elementor-accordion .elementor-accordion-item  { 
	  border: 1px solid #d4d4d4; 
	  border-top-width: 1px; 
	  border-right-width: 1px; 
	  border-bottom-width: 1px; 
	  border-left-width: 1px; 
	  border-top-style: solid; 
	  border-right-style: solid; 
	  border-bottom-style: solid; 
	  border-left-style: solid; 
	  border-top-color: rgb(212, 212, 212); 
	  border-right-color: rgb(212, 212, 212); 
	  border-bottom-color: rgb(212, 212, 212); 
	  border-left-color: rgb(212, 212, 212); 
	  border-image-source: initial; 
	  border-image-slice: initial; 
	  border-image-width: initial; 
	  border-image-outset: initial; 
	  border-image-repeat: initial;
  } 
  
  @media all{ 
	.elementor-element.elementor-element-06daeac .elementor-accordion-item  { 
	  border-width: 0px; 
	  border-top-width: 0px; 
	  border-right-width: 0px; 
	  border-bottom-width: 0px; 
	  border-left-width: 0px;
	} 
  }     
  
  .elementor-accordion .elementor-accordion-item + .elementor-accordion-item  { 
	  border-top: none; 
	  border-top-width: medium; 
	  border-top-width: initial; 
	  border-top-style: none; 
	  border-top-color: currentColor; 
	  border-top-color: initial;
  } 
  
  .elementor-accordion .elementor-tab-title  { 
	  margin: 0; 
	  padding: 15px 20px; 
	  font-weight: 700; 
	  line-height: 1; 
	  cursor: pointer; 
	  outline: none; 
	  margin-top: 0px; 
	  margin-right: 0px; 
	  margin-bottom: 0px; 
	  margin-left: 0px; 
	  padding-top: 15px; 
	  padding-right: 20px; 
	  padding-bottom: 15px; 
	  padding-left: 20px; 
	  outline-color: invert; 
	  outline-color: initial; 
	  outline-style: none; 
	  outline-width: medium; 
	  outline-width: initial;
  } 
  
  @media all{ 
	.elementor-element.elementor-element-06daeac .elementor-tab-title  { 
	  padding: 0em 0em 0em 0em; 
	  padding-top: 0em; 
	  padding-right: 0em; 
	  padding-bottom: 0em; 
	  padding-left: 0em;
	} 
  }     
  
  @media all{ 
	.elementor-element.elementor-element-06daeac .elementor-accordion-item .elementor-tab-title.elementor-active  { 
	  border-width: 0px; 
	  border-top-width: 0px; 
	  border-right-width: 0px; 
	  border-bottom-width: 0px; 
	  border-left-width: 0px;
	} 
  }     
  
  @media all{ 
	.elementor-widget-accordion .elementor-tab-content  { 
	  color: var( --e-global-color-text ); 
	  font-family: var( --e-global-typography-text-font-family ), Sans-serif; 
	  font-weight: var( --e-global-typography-text-font-weight );
	} 
  }     
  
  .elementor-accordion .elementor-tab-content  { 
	  display: none; 
	  padding: 15px 20px; 
	  border-top: 1px solid #d4d4d4; 
	  padding-top: 15px; 
	  padding-right: 20px; 
	  padding-bottom: 15px; 
	  padding-left: 20px; 
	  border-top-width: 1px; 
	  border-top-style: solid; 
	  border-top-color: rgb(212, 212, 212);
  } 
  
  @media all{ 
	.elementor-element.elementor-element-06daeac .elementor-tab-content  { 
	  color: rgb(0, 0, 0); 
	  font-family: Avenir, sans-serif; 
	  font-size: 15px; 
	  font-weight: 400; 
	  padding: 1.25em 0em 0em 0em; 
	  padding-top: 1.25em; 
	  padding-right: 0em; 
	  padding-bottom: 0em; 
	  padding-left: 0em;
	} 
  }     
  
  @media all{ 
	.elementor-element.elementor-element-06daeac .elementor-accordion-item .elementor-tab-content  { 
	  border-width: 0px; 
	  border-top-width: 0px; 
	  border-right-width: 0px; 
	  border-bottom-width: 0px; 
	  border-left-width: 0px;
	} 
  }     
  
  @media all{ 
	.elementor-clearfix:after { 
	  content: ""; 
	  display: block; 
	  clear: both; 
	  width: 0px; 
	  height: 0px;
	} 
  }     
  
  @media all{ 
	.elementor-widget-accordion .elementor-accordion-icon  { 
	  color: var( --e-global-color-primary );
	} 
  }     
  
  @media all{ 
	.elementor-widget-accordion .elementor-active .elementor-accordion-icon  { 
	  color: var( --e-global-color-accent );
	} 
  }     
  
  .elementor-accordion .elementor-tab-title .elementor-accordion-icon  { 
	  display: inline-block; 
	  width: 1.5em;
  } 
  
  @media all{ 
	.elementor-element.elementor-element-06daeac .elementor-accordion-icon  { 
	  color: rgb(0, 0, 0);
	} 
  }     
  
  .elementor-accordion .elementor-tab-title .elementor-accordion-icon.elementor-accordion-icon-right  { 
	  float: right; 
	  text-align: right;
  } 
  
  @media all{ 
	.elementor-element.elementor-element-06daeac .elementor-active .elementor-accordion-icon  { 
	  color: rgb(0, 0, 0);
	} 
  }     
  
  @media all{ 
	a { 
	  transition: all 0.15s linear; 
	  transition-duration: 0.15s; 
	  transition-timing-function: linear; 
	  transition-delay: 0s; 
	  transition-property: all;
	} 
  }     
  
  @media all{ 
	a { 
	  color: rgb(205, 38, 83); 
	  text-decoration: underline; 
	  -webkit-text-decoration-line: underline; 
	          text-decoration-line: underline; 
	  text-decoration-thickness: initial; 
	  -webkit-text-decoration-style: solid; 
	          text-decoration-style: solid; 
	  -webkit-text-decoration-style: initial; 
	          text-decoration-style: initial; 
	  -webkit-text-decoration-color: inherited; 
	          text-decoration-color: inherited; 
	  -webkit-text-decoration-color: initial; 
	          text-decoration-color: initial;
	} 
  }     
  
  a { 
	  color: rgb(205, 38, 83);
  } 
  
  @media all{ 
	a  { 
	  box-shadow: none; 
	  text-decoration: none; 
	  -webkit-text-decoration-line: none; 
	          text-decoration-line: none; 
	  text-decoration-thickness: initial; 
	  -webkit-text-decoration-style: solid; 
	          text-decoration-style: solid; 
	  -webkit-text-decoration-style: initial; 
	          text-decoration-style: initial; 
	  -webkit-text-decoration-color: inherited; 
	          text-decoration-color: inherited; 
	  -webkit-text-decoration-color: initial; 
	          text-decoration-color: initial;
	} 
  }     
  
  @media all{ 
	.elementor-widget-accordion .elementor-accordion-title  { 
	  color: var( --e-global-color-primary );
	} 
  }     
  
  @media all{ 
	.elementor-widget-accordion .elementor-accordion-title  { 
	  font-family: var( --e-global-typography-primary-font-family ), Sans-serif; 
	  font-weight: var( --e-global-typography-primary-font-weight );
	} 
  }     
  
  @media all{ 
	.elementor-widget-accordion .elementor-active .elementor-accordion-title  { 
	  color: var( --e-global-color-accent );
	} 
  }     
  
  @media all{ 
	.elementor-element.elementor-element-06daeac .elementor-accordion-title  { 
	  color: rgb(0, 0, 0);
	} 
  }     
  
  @media all{ 
	.elementor-element.elementor-element-06daeac .elementor-accordion-title  { 
	  font-family: Avenir, sans-serif; 
	  font-weight: 600;
	} 
  }     
  
  @media all{ 
	.elementor-element.elementor-element-06daeac .elementor-active .elementor-accordion-title  { 
	  color: rgb(0, 0, 0);
	} 
  }     
  
  @media all{ 
	a:hover { 
	  text-decoration: none; 
	  -webkit-text-decoration-line: none; 
	          text-decoration-line: none; 
	  text-decoration-thickness: initial; 
	  -webkit-text-decoration-style: solid; 
	          text-decoration-style: solid; 
	  -webkit-text-decoration-style: initial; 
	          text-decoration-style: initial; 
	  -webkit-text-decoration-color: inherited; 
	          text-decoration-color: inherited; 
	  -webkit-text-decoration-color: initial; 
	          text-decoration-color: initial;
	} 
  }     
  
  @media all{ 
	p { 
	  overflow-wrap: break-word;
	} 
  }     
  
  @media all{ 
	p { 
	  border: none; 
	  margin: 0; 
	  padding: 0; 
	  border-top-width: medium; 
	  border-top-width: initial; 
	  border-right-width: medium; 
	  border-right-width: initial; 
	  border-bottom-width: medium; 
	  border-bottom-width: initial; 
	  border-left-width: medium; 
	  border-left-width: initial; 
	  border-top-style: none; 
	  border-right-style: none; 
	  border-bottom-style: none; 
	  border-left-style: none; 
	  border-top-color: currentColor; 
	  border-top-color: initial; 
	  border-right-color: currentColor; 
	  border-right-color: initial; 
	  border-bottom-color: currentColor; 
	  border-bottom-color: initial; 
	  border-left-color: currentColor; 
	  border-left-color: initial; 
	  border-image-source: initial; 
	  border-image-slice: initial; 
	  border-image-width: initial; 
	  border-image-outset: initial; 
	  border-image-repeat: initial; 
	  margin-top: 0px; 
	  margin-right: 0px; 
	  margin-bottom: 0px; 
	  margin-left: 0px; 
	  padding-top: 0px; 
	  padding-right: 0px; 
	  padding-bottom: 0px; 
	  padding-left: 0px;
	} 
  }     
  
  @media all{ 
	p { 
	  line-height: 1.5; 
	  margin: 0 0 1em 0; 
	  margin-top: 0px; 
	  margin-right: 0px; 
	  margin-bottom: 1em; 
	  margin-left: 0px;
	} 
  }     
  
  @media all{ 
	p { 
	  margin-bottom: 0px; 
	  margin-top: 0px;
	} 
  }     
  
  .elementor-accordion .elementor-tab-title .elementor-accordion-icon .elementor-accordion-icon-closed  { 
	  display: block;
  } 
  
  .elementor-accordion .elementor-tab-title.elementor-active .elementor-accordion-icon-closed  { 
	  display: none;
  } 
  
  .elementor-accordion .elementor-tab-title .elementor-accordion-icon .elementor-accordion-icon-opened  { 
	  display: none;
  } 
  
  .elementor-accordion .elementor-tab-title.elementor-active .elementor-accordion-icon-opened  { 
	  display: block;
  } 
  
  @media all{ 
	svg { 
	  display: block; 
	  height: auto; 
	  max-width: 100%;
	} 
  }     
  
  @media all{ 
	.elementor-widget-accordion .elementor-accordion-icon svg  { 
	  fill: var( --e-global-color-primary );
	} 
  }     
  
  @media all{ 
	.elementor-widget-accordion .elementor-active .elementor-accordion-icon svg  { 
	  fill: var( --e-global-color-accent );
	} 
  }     
  
  .elementor-accordion .elementor-tab-title .elementor-accordion-icon svg  { 
	  width: 1em; 
	  height: 1em;
  } 
  
  @media all{ 
	.elementor-element.elementor-element-06daeac .elementor-accordion-icon svg  { 
	  fill: rgb(0, 0, 0);
	} 
  }     
  
  @media all{ 
	.elementor-element.elementor-element-06daeac .elementor-active .elementor-accordion-icon svg  { 
	  fill: rgb(0, 0, 0);
	} 
  }     
  
  
  
